import Vue from 'vue'
import Vuex from 'vuex'
import state from '@/store/state'
import mutations from '@/store/mutations'
import actions from '@/store/actions'
import getters from '@/store/getters'
import constants from '@/constants'
import cart from '@/store/modules/cart'
import dashboard from '@/store/modules/dashboard'
import exchangeHistory from '@/store/modules/exchangeHistory'
import favoriteItem from '@/store/modules/favoriteItem'
import item from '@/store/modules/item'
import message from '@/store/modules/message'
import order from '@/store/modules/order'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
  modules: {
    cart,
    dashboard,
    exchangeHistory,
    favoriteItem,
    item,
    message,
    order
  },
  plugins: [
    createPersistedState({
      key: constants.STORE_KEY,
      storage: window.localStorage
    })
  ]
})
