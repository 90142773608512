<template>
  <v-app-bar
    :style="styles.header"
    :elevation="0"
    clipped-left
    app
  >
    <div class="header_wrapper">
      <div class="header_container">
        <!-- site logo -->
        <router-link
          :to="{ name: 'Dashboard' }"
          class="logo-wrapper"
        >
          <v-img
            v-if="$vuetify.breakpoint.mdAndUp"
            class="logo"
            src="@/assets/img/logo_lg.png"
            :alt="logAlt"
          />
          <v-img
            v-else
            class="logo"
            src="@/assets/img/logo_sm.png"
            :alt="logAlt"
          />
        </router-link>
        <search-form />
      </div>
      <div class="header_container">
        <div
          v-if="$vuetify.breakpoint.mdAndUp"
          class="possession_point"
        >
          所持ポイント
          <span class="point">{{ myPoint }}</span>
        </div>
        <div class="btn_container">
          <link-icon
            icon="mdi-human-dolly"
            :url="questUrl"
            text="稼ぐ"
            class="icon-item"
          />
          <link-icon
            icon="mdi-cart"
            path="CartDetail"
            text="カート"
            :item-count="cartItemsCount"
            class="icon-item"
          />
          <link-icon
            icon="mdi-heart"
            path="FavoriteItem"
            text="お気に入り"
            class="icon-item"
          />
          <div class="icon-item">
            <hover-user-menu
              :my-point="myPoint"
              :md-and-up-flag="$vuetify.breakpoint.mdAndUp"
            />
          </div>
        </div>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import CommonMixin from '@/mixins/CommonMixin'
import UtilMethodsMixin from '@/mixins/UtilMethodsMixin'
import constants from '@/constants'

/* component */
import searchForm from './SearchForm'
import linkIcon from './LinkIcon'
import hoverUserMenu from './HoverUserMenu'

export default {
  name: 'AppHeader',

  components: {
    searchForm,
    linkIcon,
    hoverUserMenu
  },

  mixins: [
    CommonMixin,
    UtilMethodsMixin
  ],

  data: function () {
    return {
      logAlt: constants.SITE_NAME,
      questUrl: constants.QUEST_TOP_URL
    }
  },

  computed: {
    ...mapGetters([
      'cartItemsCount'
    ]),
    myPoint () {
      return this.$store.state.userInfo ? this.intcomma(this.$store.state.userInfo.point) : 0
    },
    styles () {
      return {
        header: {
          background: `linear-gradient(90deg, ${this.$vuetify.theme.themes.light.background}, ${this.$vuetify.theme.themes.light.primary}) !important`,
          color: this.$vuetify.theme.themes.light.black
        },
        logout: {
          color: this.$vuetify.theme.themes.light.white,
          'box-shadow': `7px 7px 7px #af94d3, -7px -7px 7px #d1baf1 !important`
        }
      }
    }
  },

  watch: {
    $route (to, from) {
      if (to.path === '/item/list') {
        this.setSearchCondition()
      } else {
        this.searchKeyword = ''
      }
    }
  },

  methods: {
    setSearchCondition () {
      const queryParamKeyword = this.$route.query.keywords
      if (queryParamKeyword) {
        this.searchKeyword = queryParamKeyword
      }
      const queryParamCategory = this.$route.query.category
      if (queryParamCategory) {
        this.searchCategory = queryParamCategory
      }
    },
    getPoint () {
      return this.$store.state.userInfo ? this.intcomma(this.$store.state.userInfo.point) : 0
    }
  }
}
</script>

<style lang="scss" scoped>

.header_wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 64px;
  width: 100%;
  z-index: 10;
}

.header_container {
  align-items: center;
  display: flex;
  height: 100%;
  width: 50%;

  .logo-wrapper {
    max-width: 400px;
    width: 30%;

    .logo {
      width: 100%;
    }

    &:hover {
      opacity: .7;
    }
  }

  &:nth-child(n+1) {
    justify-content: flex-start;
    width: 70%;
  }

  &:last-child {
    justify-content: flex-end;
    width: 30%;
  }
}

.btn_container {
  align-items: center;
  display: flex;
  margin-left: 30px;
}

.possession_point {
  text-align: right;
  font-size: .5rem;

  .point {
    display: block;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1rem;
    margin-top: .5rem;

    &::after {
      content: 'pt';
      font-size: 1rem;
      font-weight: normal;
      margin-left: 2.5px;
    }
  }
}

.icon-item {
  &:nth-child(n+2) {
    margin-left: 15px;
  }
}
</style>
