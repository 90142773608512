import store from '@/store'
import ExchangeHistory from '@/models/ExchangeHistory.js'

export default {

  /**
   * ポイント交換履歴一覧取得
   */
  async getList () {
    if (!store.state.accessToken) {
      return []
    }
    const response = await ExchangeHistory.getAll()
    return response.data
  }
}

