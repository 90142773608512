import { mapGetters } from 'vuex'
import constants from '@/constants'

export default {
  data: () => ({
    constants
  }),
  computed: {
    ...mapGetters([
      'loading'
    ]),
    styles () {
      return {
        app: {
          background: this.$vuetify.theme.themes.light.background,
          color: this.$vuetify.theme.themes.light.black
        }
      }
    }
  }
}
