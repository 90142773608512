export default {
  methods: {
    /**
     * 数字,文字列を3桁区切りにして返却
     * @parm {*} num
     * @return {string} - 3桁区切りの文字列
     */
    intcomma (num) {
      if (!isFinite(num)) return num
      return Number(num).toLocaleString()
    },
    /**
     * 当日判定
     */
    isToday (datetime) {
      const today = new Date()
      return (
        datetime.getDate() === today.getDate() &&
        datetime.getMonth() === today.getMonth() &&
        datetime.getFullYear() === today.getFullYear()
      )
    },
    /**
     * ポイント交換申請(履歴)の各アイテムについて下記を施した配列を返却
     *   - 有効期限切れフラグ
     *   - 有効期限文字列：未設定の場合「なし」
     *   - 管理者備考文字列：未設定の場合「-」
     *   - ユーザー備考文字列：未設定の場合「-」
     * @parm {Object} order - ポイント交換申請(履歴)
     * @parm {boolean} updatable - 申請の更新可否：falseの場合、変更不可=表示専用とし表示用の文字列をセットする
     * @return {Array} - 新しいポイント交換申請(履歴)配列
     */
    setOrderItemDisplayAttributes (order, updatable) {
      if (!order) {
        return {}
      }
      for (const item of order.items) {
        if (!updatable) {
          item.adminNote = item.adminNote ? item.adminNote : '-'
        }
        item.userNote = item.userNote ? item.userNote : '-'
        item.expirationDate = item.expirationDate ? item.expirationDate : 'なし'
        if (!item.expirationDate) {
          continue
        }
        item.isExpired = new Date(item.expirationDate) < new Date()
      }
      return order
    }
  }
}
