export default {
  STORE_KEY: process.env.VUE_APP_TITLE.toUpperCase(),
  API_HOST: process.env.VUE_APP_API_HOST,
  API_KEY: process.env.VUE_APP_API_KEY,
  QUEST_TOP_URL: process.env.VUE_APP_QUEST_TOP_URL,
  FADE_OUT_MS: 300,
  MAX_COUNT_PER_ITEM: 10
}

export const SITE_NAME = 'Welfare Imagineering'
export const CANCEL_DEAD_LINE = 18 // 時
export const CATEGORIES = [
  '全カテゴリー',
  '会食',
  '食品',
  '雑貨',
  '健康・美容',
  'レジャー'
]

export const ORDER_STATUS = {
  OPEN: 100,
  CLOSED: 200
}
export const ORDER_STATUS_LABEL = {
  [ORDER_STATUS.OPEN]: 'Open',
  [ORDER_STATUS.CLOSED]: 'Closed'
}
export const ITEM_STATUS = {
  IN_PROGRESS: 100,
  CLOSED: 200,
  CANCELED: 300
}
export const ITEM_STATUS_LABEL = {
  [ITEM_STATUS.IN_PROGRESS]: '準備中',
  [ITEM_STATUS.CLOSED]: '完了',
  [ITEM_STATUS.CANCELED]: 'キャンセル済み'
}
