<template>
  <v-app>
    <loading-overlay />
    <div
      v-if="show"
    >
      <app-header />
    </div>

    <app-message />
    <v-main
      :style="styles.app"
    >
      <router-view />
    </v-main>

    <app-footer
      v-if="show"
    />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import AppHeader from '@/components/common/header/AppHeader'
import AppFooter from '@/components/common/AppFooter'
import AppMessage from '@/components/common/AppMessage'
import LoadingOverlay from '@/components/common/overlays/AppLoadingOverlay'

export default {
  name: 'App',

  components: {
    AppHeader,
    AppFooter,
    LoadingOverlay,
    AppMessage
  },

  data: () => ({
    isEntered: false
  }),

  computed: {
    ...mapGetters([
      'constants',
      'isContentsOnly'
    ]),
    show () {
      return !(this.isContentsOnly) && this.isEntered
    },
    styles () {
      return {
        app: {
          background: this.$vuetify.theme.themes.light.background,
          color: this.$vuetify.theme.themes.light.black
        }
      }
    }
  },

  created () {
    this.$store.commit('updateLoading', { flag: true })
    this.onEntered()
    this.$store.commit('updateLoading', { flag: false })
  },

  methods: {
    onEntered () {
      this.isEntered = false
      if (!this.$store.getters.accessToken && (window.location.pathname !== '/login')) {
        this.$store.commit('message/show', {
          title: 'Error',
          message: 'ログインしてください',
          color: 'error',
          timeout: 6000
        })
        this.$router.push({ name: 'Login' })
      }
      setTimeout(() => {
        this.isEntered = true
      }, this.constants.FADE_OUT_MS)
    }
  }
}
</script>
