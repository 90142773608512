<template>
  <div>
    <router-link
      v-if="path"
      :to="{ name: path }"
      class="icon-container"
    >
      <v-icon
        class="icon"
        large
      >
        {{ icon }}
      </v-icon>
      <v-badge
        v-if="itemCount"
        :content="itemCount"
        color="error"
        overlap
        class="badge"
      />
      <p
        v-if="text"
        class="text"
      >
        {{ text }}
      </p>
    </router-link>

    <a
      v-else
      :href="url"
      target="_blank"
      rel="noopener noreferrer"
      class="icon-container"
    >
      <v-icon
        class="icon"
        large
      >
        {{ icon }}
      </v-icon>
      <p
        v-if="text"
        class="text"
      >
        {{ text }}
      </p>
    </a>
  </div>
</template>

<script>
export default {
  name: 'LinkIcon',

  /**
   * icon: アイコン名
   * path?: パス名
   * url?: URL(外部リンク)
   * text?: アイコン下テキスト
   * itemCount?: badge内の数字
   */
  props: {
    icon: {
      type: String,
      required: true
    },
    path: {
      type: String || undefined,
      default: undefined
    },
    url: {
      type: String || undefined,
      default: undefined
    },
    text: {
      type: String || undefined,
      default: undefined
    },
    itemCount: {
      type: Number || undefined,
      default: undefined
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-container {
  position: relative;
  text-align: center;
  text-decoration: none;

  &:hover {
    opacity: .7;
  }

  .icon {
    color: #fff;
    display: block;
  }

  .badge {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .text {
    color: #fff;
    font-size: .7rem;
    margin: 0;
  }
}
</style>
