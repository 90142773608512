import store from '@/store'

export default {
  /**
   * メッセージ表示
   */
  show (state, payload) {
    state.messages.push({
      id: String(state.messages.length),
      showMessage: true,
      color: payload.color,
      message: payload.message,
      multiple: payload.multiple
    })
    if (payload.timeout) {
      setTimeout(() => {
        store.commit('message/close', String(state.messages.length - 1))
      }, payload.timeout)
    }
  },
  /**
   * メッセージを閉じる
   */
  close (state, id) {
    state.messages.forEach(msg => {
      if (msg.id !== id) return
      msg.showMessage = false
    })
  },
  /**
   * メッセージクリア
   */
  clear (state) {
    state.messages = []
  }
}
