import baseModel from './BaseModel.js'

const errorHandler = (err) => {
  baseModel.errorHandler(err)
}

const ENDPOINT = 'order/'

/**
 * 交換申請一覧取得
 */
function getList () {
  const url = `${ENDPOINT}`
  return baseModel.GET(url)
    .then(res => {
      return res
    })
    .catch(error => {
      errorHandler(error)
      return []
    })
}

/**
 * 交換申請詳細取得
 */
function getDetail (orderNumber) {
  const url = `${ENDPOINT}${orderNumber}/`
  return baseModel.GET(url)
    .then(res => {
      return res
    })
    .catch(error => {
      errorHandler(error)
      return {}
    })
}

/**
 * 交換申請更新
 */
function update (orderNumber, data) {
  const url = `${ENDPOINT}${orderNumber}/`
  return baseModel.PUT(url, data, 'application/json')
}

/**
 * 交換申請キャンセル
 */
function cancel (orderNumber, data) {
  const url = `${ENDPOINT}${orderNumber}/cancel/`
  return baseModel.POST(url, data, 'application/json')
}

export default {
  getList,
  getDetail,
  update,
  cancel,
  errorHandler: errorHandler
}
