const palx = require('palx')
const colors = palx('#c4a6ed')

var lightColors = {}
var darkColors = {}

function copy (obj) {
  return JSON.parse(JSON.stringify(obj))
}

for (var key in colors) {
  if (colors[key] instanceof Object) {
    lightColors[key] = copy(colors[key]).splice(5)
    darkColors[key] = copy(colors[key]).splice(0, 5)
  }
}

export default {
  options: {
    customProperties: true
  },
  themes: {
    light: {
      black: colors.black,
      white: colors.red[0],
      default: colors.gray[9],
      primary: colors.base,
      secondary: colors.pink[7],
      accent: colors.blue[7],
      info: colors.cyan[4],
      success: colors.lime[5],
      warning: colors.orange[5],
      error: colors.red[6],
      background: colors.gray[0],
      ...lightColors
    },
    dark: {
      black: colors.black,
      white: colors.red[0],
      default: colors.gray[0],
      primary: colors.base,
      secondary: colors.pink[7],
      accent: colors.blue[7],
      info: colors.cyan[4],
      success: colors.green[5],
      warning: colors.orange[5],
      error: colors.red[6],
      background: colors.gray[9],
      ...darkColors
    }
  }
}
