import store from '@/store'
import FavoriteItem from '@/models/FavoriteItem.js'

export default {
  /**
   * お気に入りアイテム一覧習得
   */
  async getFavoriteItems () {
    if (!store.state.accessToken) {
      return []
    }

    const response = await FavoriteItem.getFavoriteItems()
    return response.data
  },
  /**
   * お気に入りにアイテム追加
   */
  async addFavoriteItem (_, data) {
    if (!store.state.accessToken) {
      return []
    }

    const response = await FavoriteItem.addFavoriteItem(data)
    return response
  },
  /**
   * お気に入りにアイテム追加
   */
  async removeFavoriteItem (_, itemUuid) {
    if (!store.state.accessToken) {
      return []
    }
    const response = await FavoriteItem.removeFavoriteItem(itemUuid)
    return response
  }
}
