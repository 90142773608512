import store from '@/store'
import baseModel from './BaseModel.js'

const errorHandler = (err) => {
  if (err.code === 'auth/user-not-found' || err.code === 'auth/wrong-password' || err.code === 'auth/invalid-email') {
    store.commit('message/show', {
      title: 'Error',
      message: 'ログイン情報をご確認ください。',
      color: 'error',
      timeout: 5000
    })
    store.commit('signOut')
  } else {
    baseModel.errorHandler(err)
  }
}

const ENDPOINT = 'user/'

function getProfile () {
  const url = `${ENDPOINT}profile/`
  return baseModel.GET(url)
}

export default {
  getProfile,
  errorHandler: errorHandler
}
