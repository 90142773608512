import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'
import multiguard from 'vue-router-multiguard'
import { SITE_NAME } from '@/constants'

Vue.use(VueRouter)

/**
 * ログインユーザー管理者確認Guard
 */
const staffGuard = (to, from, next) => {
  if (store.getters.isStaff) {
    next()
  } else {
    store.commit('message/show', {
      title: 'Error',
      message: '許可されていない操作が行われました。',
      color: 'error',
      timeout: 5000
    })
    store.commit('signOut')
  }
}

const routes = [
  {
    path: '/login/',
    name: 'Login',
    meta: {
      title: 'ログイン',
      showHeader: true
    },
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/',
    redirect: { name: 'Dashboard' }
  },

  /** user router **/
  {
    path: '/dashboard/',
    name: 'Dashboard',
    meta: {
      title: 'ダッシュボード',
      showHeader: true
    },
    component: () => import('@/views/user/dashboard/Dashboard.vue')
  },
  {
    path: '/item/list/',
    name: 'ItemList',
    meta: {
      title: '検索結果',
      showHeader: true
    },
    component: () => import('@/views/user/item/List.vue')
  },
  {
    path: '/item/detail/:uuid',
    name: 'ItemDetail',
    meta: {
      title: 'アイテム詳細',
      showHeader: true
    },
    component: () => import('@/views/user/item/Detail.vue')
  },
  {
    path: '/cart/detail/',
    name: 'CartDetail',
    meta: {
      title: 'カート',
      showHeader: true
    },
    component: () => import('@/views/user/cart/Detail.vue')
  },
  {
    path: '/exchange/complete/:orderNumber',
    name: 'ExchangeComplete',
    meta: {
      title: '交換完了',
      showHeader: true
    },
    component: () => import('@/views/user/cart/ExchangeComplete.vue')
  },
  {
    path: '/exchange/history/',
    name: 'ExchangeHistory',
    meta: {
      title: 'ポイント交換履歴',
      showHeader: true
    },
    component: () => import('@/views/user/exchangeHistory/List.vue')
  },
  {
    path: '/favorite-item/',
    name: 'FavoriteItem',
    meta: {
      title: 'お気に入りアイテム一覧',
      showHeader: true
    },
    component: () => import('@/views/user/favoriteItem/List.vue')
  },

  /** manage router **/
  {
    path: '/manage/order/',
    name: 'OrderList',
    beforeEnter: multiguard([staffGuard]),
    meta: {
      title: '交換申請管理',
      showHeader: true
    },
    component: () => import('@/views/manage/order/List.vue')
  },
  {
    path: '/manage/order/detail/:orderNumber',
    name: 'OrderDetail',
    beforeEnter: multiguard([staffGuard]),
    meta: {
      title: '交換管理詳細',
      showHeader: true
    },
    component: () => import('@/views/manage/order/Detail.vue')
  },
  {
    path: '/manage/item/list/',
    name: 'ManageItemList',
    beforeEnter: multiguard([staffGuard]),
    meta: {
      title: 'アイテム管理',
      showHeader: true
    },
    component: () => import('@/views/manage/item/List.vue')
  },
  {
    path: '/manage/item/detail/:uuid',
    name: 'ManageItemDetail',
    beforeEnter: multiguard([staffGuard]),
    meta: {
      title: 'アイテム管理詳細',
      showHeader: true
    },
    component: () => import('@/views/manage/item/Detail.vue')
  },
  {
    path: '/manage/item/create/',
    name: 'ManageItemCreate',
    beforeEnter: multiguard([staffGuard]),
    meta: {
      title: 'アイテム登録',
      showHeader: true
    },
    component: () => import('@/views/manage/item/Create.vue')
  },
  {
    path: '/manage/item/edit/:uuid',
    name: 'ManageItemEdit',
    beforeEnter: multiguard([staffGuard]),
    meta: {
      title: 'アイテム編集',
      showHeader: true
    },
    component: () => import('@/views/manage/item/Edit.vue')
  },

  /** Not Found **/
  {
    path: '/*',
    name: 'NotFound',
    meta: {
      title: 'ページが見つかりません',
      showHeader: true
    },
    component: () => import('@/views/user/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/**
 * 遷移後にタイトルを設定する
 */
router.afterEach((to, from) => {
  const title = to.meta.title ? `${to.meta.title} | ${SITE_NAME}` : SITE_NAME
  document.title = title
})

export default router
