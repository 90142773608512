import constants from '@/constants'

export default {
  constants: () => {
    return constants
  },
  loading: (state) => {
    return state.loading
  },
  userInfo: (state) => {
    return state.userInfo
  },
  isStaff: (state) => {
    return state.userInfo ? state.userInfo.isStaff : false
  },
  accessToken: (state) => {
    return state.accessToken
  },
  isContentsOnly: (state) => {
    return state.isContentsOnly
  },
  cartInfo: (state) => {
    return state.cartInfo
  },
  cartItemsCount: (state) => {
    if (!state.cartInfo || !state.cartInfo.contents) {
      return 0
    }
    return state.cartInfo.contents.length
  }
}
