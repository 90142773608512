import firebase from 'firebase/app'
import 'firebase/auth'

const config = require('../../firebaseConfig.json')
firebase.initializeApp(config)
if (!firebase.app) {
  firebase.initializeApp()
}

switch (process.env.NODE_ENV) {
  case 'development': {
    console.log('Welfare is running in debug mode...')
  }
}

export default firebase
