import store from '@/store'
import Item from '@/models/Item.js'

export default {
  /**
   * アイテム一覧取得
   */
  async getList (_, params) {
    if (!store.state.accessToken) {
      return []
    }
    const items = await Item.getList(params)
    return items.data
  },
  /**
   * アイテム詳細取得
   */
  async getDetail (_, uuid) {
    if (!store.state.accessToken) {
      return []
    }
    const item = await Item.retrieve(uuid)
    return item.data
  },
  /**
   * アイテム新規作成
   */
  async create (_, payload) {
    if (!store.state.accessToken) {
      return []
    }
    return await Item.create(payload)
  },
  /**
   * アイテム編集
   */
  async update (_, payload) {
    if (!store.state.accessToken) {
      return []
    }
    return await Item.update(payload)
  },
  /**
   * アイテム削除
   */
  async remove (_, uuid) {
    if (!store.state.accessToken) {
      return []
    }
    return await Item.remove(uuid)
  }
}
