import store from '@/store'
import Cart from '@/models/Cart.js'
import User from '@/models/User.js'

export default {
  /**
   * カート内アイテム数取得
   */
  async getCartItemsCount (_) {
    if (!store.state.accessToken) {
      return []
    }
    const cartItems = await Cart.getCartItemsCount(store.state.userInfo.email)
    return cartItems.data.count
  },
  /**
   * カート内アイテム一覧取得
   */
  async getCartItems (_) {
    if (!store.state.accessToken) {
      return []
    }
    const cartItems = await Cart.getCartItems()
    return cartItems.data
  },
  /**
   * カートにアイテム追加
   */
  async addItems (_, data) {
    if (!store.state.accessToken) {
      return []
    }
    const response = await Cart.addItems(data)
    return response
  },
  /**
   * カートからアイテム削除
   */
  async removeItem (_, itemUuid) {
    if (!store.state.accessToken) {
      return []
    }
    const response = await Cart.removeItem(itemUuid)
    return response
  },
  /**
   * ポイント交換
   */
  async exchange (_, data) {
    if (!store.state.accessToken) {
      return []
    }
    const response = await Cart.exchange(data.cartUuid, data.contents)
    return response.orderNumber
  },
  /**
   * ユーザー情報取得
   */
  async getUser (_) {
    const res = await User.getProfile()
    return res.data
  }
}
