import baseModel from './BaseModel.js'

const errorHandler = (err) => {
  baseModel.errorHandler(err)
}

const ENDPOINT = 'cart/'

/**
 * カート内アイテム数取得
 */
function getCartItemsCount () {
  const url = `${ENDPOINT}count/`
  return baseModel.GET(url)
}

/**
 * カート内アイテム一覧取得
 */
function getCartItems () {
  const url = `${ENDPOINT}`
  return baseModel.GET(url)
}

/**
 * カートにアイテム追加
 */
function addItems (data) {
  const url = `${ENDPOINT}add/`
  return baseModel.POST(url, data, 'application/json')
}

/**
 * カートからアイテム削除
 */
function removeItem (itemUuid) {
  const url = `${ENDPOINT}remove?item_uuid=${itemUuid}`
  return baseModel.DELETE(url, 'application/json')
}

/**
 * ポイント交換
 */
function exchange (cartUuid, data) {
  const url = `${ENDPOINT}${cartUuid}/exchange/`
  return baseModel.POST(url, data, 'application/json')
}

export default {
  getCartItemsCount,
  getCartItems,
  addItems,
  removeItem,
  exchange,
  errorHandler: errorHandler
}
