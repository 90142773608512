const DEFAULT_STATE = {
  currentUser: null, // 現在のログイン中ユーザー(firebase)
  userInfo: null, // 現在のログイン中ユーザー(welfare)
  loading: false, // ローディング中 or Not
  accessToken: null, // ログイン中ユーザーのアクセストークン
  isContentsOnly: false, // ページコンテンツのみ表示 or Not
  cartInfo: null // カート内アイテム情報
}

export default {
  ...DEFAULT_STATE,
  getInitialState: () => {
    return Object.assign({}, DEFAULT_STATE)
  }
}
