import baseModel from './BaseModel.js'

const errorHandler = (err) => {
  baseModel.errorHandler(err)
}

const ENDPOINT = 'favorite/'

/**
 * お気に入りアイテム一覧取得
 */
function getFavoriteItems () {
  const url = `${ENDPOINT}`
  return baseModel.GET(url)
}

/**
 * お気に入りにアイテム追加
 */
function addFavoriteItem (data) {
  const url = `${ENDPOINT}add/`
  return baseModel.POST(url, data, 'application/json')
}

/**
 * お気に入りからアイテム削除
 */
function removeFavoriteItem (itemUuid) {
  const url = `${ENDPOINT}${itemUuid}/`
  return baseModel.DELETE(url)
}

export default {
  getFavoriteItems,
  addFavoriteItem,
  removeFavoriteItem,
  errorHandler: errorHandler
}
