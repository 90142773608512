import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

let auth = null
const config = require('../../authFirebaseConfig.json')
const firebaseAuthApp = firebase.initializeApp(config, 'firebaseAuth')

switch (process.env.NODE_ENV) {
  case 'development': {
    console.log('Auth is running in debug mode...')
    auth = firebase.auth(firebaseAuthApp)
    // auth.useEmulator('http://localhost:9099')
    break
  }
  case 'staging': {
    auth = firebase.auth(firebaseAuthApp)
    break
  }
  case 'production': {
    auth = firebase.auth(firebaseAuthApp)
    break
  }
}

export const firebaseAuth = auth
