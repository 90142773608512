import store from '@/store'
import Item from '@/models/Item.js'
import Topic from '@/models/Topic.js'

export default {
  /**
   * お知らせ一覧取得
   */
  async getTopics () {
    if (!store.state.accessToken) {
      return []
    }
    const topics = await Topic.getList()
    return topics.data
  },
  /**
   * 新着アイテム取得
   */
  async getNewArrivals () {
    if (!store.state.accessToken) {
      return []
    }
    const items = await Item.getNewArrivals()
    return items.data
  },
  /**
   * ポイント交換数ランキング取得
   */
  async getRanking () {
    if (!store.state.accessToken) {
      return []
    }
    const ranking = await Item.getRanking()
    return ranking.data
  }
}
