import constants from '@/constants'
import firebase from '@/plugins/firebase.js'
import { firebaseAuth } from '@/plugins/firebase_auth.js'
import router from '@/router'
import Cart from '@/models/Cart'
import User from '@/models/User.js'

export default {
  init (state) {
    const initialData = JSON.parse(localStorage.getItem('initialData'))
    Object.assign(state, initialData)
  },
  /**
   * サインイン
   */
  signIn (state, params) {
    state.loading = true
    firebaseAuth.signInWithEmailAndPassword(params.email, params.password)
      .then(res => {
        state.currentUser = res.user
        res.user.getIdToken()
          .then(token => {
            state.accessToken = token
            User.getProfile()
              .then(res => {
                state.userInfo = res.data
                state.messages = []
                Cart.getCartItems()
                  .then(res => {
                    state.cartInfo = res.data
                    if (router.currentRoute.query.nextTo) {
                      router.push({ path: router.currentRoute.query.nextTo })
                    } else {
                      router.push({ name: 'Dashboard' })
                    }
                  })
              })
              .catch(error => {
                User.errorHandler(error)
              })
          })
      })
      .catch(error => {
        User.errorHandler(error)
      })
  },
  /**
   * Googleサインイン
   */
  signInWithGoogle (state) {
    state.loading = true
    const provider = new firebase.auth.GoogleAuthProvider()
    provider.addScope('email')
    provider.setCustomParameters({ prompt: 'select_account' })
    firebaseAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        return firebaseAuth.signInWithRedirect(provider)
      })
  },
  /**
   * ログイン画面表示時
   */
  signInCallback (state) {
    state.loading = true
    firebaseAuth.onAuthStateChanged(() => {
      firebaseAuth.getRedirectResult()
        .then(res => {
          if (res.credential) {
            firebaseAuth.currentUser.getIdToken()
              .then(token => {
                state.currentUser = firebaseAuth.currentUser
                state.accessToken = token
                User.getProfile()
                  .then(res => {
                    state.userInfo = res.data
                    state.messages = []
                    Cart.getCartItems()
                      .then(res => {
                        state.cartInfo = res.data
                        if (router.currentRoute.query.nextTo) {
                          router.push({ path: router.currentRoute.query.nextTo })
                        } else {
                          router.push({ name: 'Dashboard' })
                        }
                      })
                  })
                  .catch(error => {
                    User.errorHandler(error)
                  })
              })
          } else {
            state.loading = false
          }
        })
    })
  },
  /**
   * サインアウト
   */
  async signOut (state) {
    state.loading = true
    await firebaseAuth.signOut()
    state.currentUser = null
    state.accessToken = null
    state.loading = false
    window.sessionStorage.removeItem(constants.STORE_KEY)
    state = state.getInitialState()
    router.push({ name: 'Login' }, () => {})
  },
  /**
   * ローディング状態更新
   */
  updateLoading (state, params) {
    state.loading = params.flag
  },
  /**
   * ユーザー情報更新
   */
  updateUserInfo (state, user) {
    state.userInfo = user
  },
  /**
   * ページコンテンツのみフラグ変更
   */
  setContentsOnly (state, params) {
    state.isContentsOnly = params.flag
  },
  /**
   * カート内アイテム情報態更新
   */
  updateCartInfo (state, cartInfo) {
    state.cartInfo = cartInfo
  },
  /**
   * カート内アイテムクリア
   */
  clearCartInfo (state, cartInfo) {
    cartInfo.contents = []
    state.cartInfo = cartInfo
  }
}
