<template>
  <div class="wrapper">
    <div class="inner">
      <v-select
        v-model="searchCategory"
        dense
        filled
        outlined
        class="radius-right select"
        :items="categories"
      />
      <v-text-field
        v-model="searchKeyword"
        dense
        solo
        flat
        name="searchKeyword"
        type="text"
        class="radius-none"
        placeholder="アイテムをキーワード検索"
        @keydown.enter="searchHandler"
      />
      <v-btn
        color="warn"
        elevation="0"
        height="38"
        class="radius-left"
        @click="searchHandler"
      >
        <v-icon>
          mdi-magnify
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { CATEGORIES } from '@/constants'

export default {
  name: 'SearchForm',

  data: () => ({
    searchKeyword: '',
    searchCategory: CATEGORIES[0], // 全カテゴリー
    categories: CATEGORIES
  }),

  methods: {
    searchHandler (event) {
      // ignore Enter key pressing for conversion to Kanji
      if (event.keyCode !== 13 && event.type !== 'click') return
      const queryParams = {
        keywords: this.searchKeyword,
        minPrice: this.$route.query.minPrice,
        maxPrice: this.$route.query.maxPrice
      }
      if (this.searchCategory !== CATEGORIES[0]) queryParams.category = this.searchCategory
      this.$router.push({ name: 'ItemList', query: queryParams }, () => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  height: 38px;
  width: 500px;
}

.inner {
  display: flex;
  width: 100%;
}

.select {
  font-size: 0.8rem;
  min-width: 100px;
  width: 10%;
}
</style>
