<template>
  <v-footer
    padless
    class="pa-3"
  >
    <v-col
      :style="styles.footer"
      class="outset"
      cols="12"
    >
      <v-row>
        <v-col>
          <!-- TODO 利用規約 -->
        </v-col>
        <v-col
          class="text-right"
        >
          <small>©︎ {{ new Date().getFullYear() }} Fabeee Inc. All rights reserved.</small>
        </v-col>
      </v-row>
    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: 'AppFooter',
  computed: {
    styles () {
      return {
        footer: {
          background: `linear-gradient(90deg, ${this.$vuetify.theme.themes.light.background}, ${this.$vuetify.theme.themes.light.primary}) !important`,
          color: this.$vuetify.theme.themes.light.black,
          'border-radius': '25px',
          'z-index': 10
        }
      }
    }
  }
}
</script>
