<template>
  <div>
    <template v-for="msg in messages">
      <v-system-bar
        v-if="msg.showMessage"
        :key="msg.id"
        :color="msg.color"
        class="notice my-2 px-5 d-flex"
        :class="msg.multiple ? '' : '.msg-overlap'"
        height="50"
        fixed
        top
        dark
      >
        <strong>{{ msg.message }}</strong>
        <v-btn
          style="position: absolute; right: 5px;"
          icon
          @click="onClose(msg.id)"
        >
          <v-icon class="mx-auto">
            mdi-close
          </v-icon>
        </v-btn>
      </v-system-bar>
    </template>
  </div>
</template>

<script>
export default {
  name: 'AppMessage',
  computed: {
    messages () {
      return this.$store.state.message.messages
    }
  },
  methods: {
    onClose: function (id) {
      this.$store.commit('message/close', id)
    }
  }
}
</script>

<style lang="scss" scoped>
.notice {
  width: 90%;
  top: 65px;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 10px;
  z-index: 5;

  opacity:0;
  animation-name: notice;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes notice {
  100% {
    opacity: 1;
  }
}

.msg-overlap {
  top: 65;
}
</style>
