import baseModel from './BaseModel.js'

const errorHandler = (err) => {
  baseModel.errorHandler(err)
}

const ENDPOINT = 'exchangeHistory/'

/**
 * ポイント交換履歴取得
 */
function getAll () {
  const url = `${ENDPOINT}`
  return baseModel.GET(url)
    .then(res => {
      return res
    })
    .catch(error => {
      errorHandler(error)
      return []
    })
}

export default {
  getAll: getAll,
  errorHandler: errorHandler
}
