import baseModel from './BaseModel.js'

const errorHandler = (err) => {
  baseModel.errorHandler(err)
}

const ENDPOINT = 'item/'

/**
 * クエリパラメータの区切り文字取得
 */
function getQueryParamDelimiter (url) {
  return url.match(/\?/) ? '&' : '?'
}

/**
 * アイテム一覧取得
 */
function getList (params) {
  var url = `${ENDPOINT}`
  if (!params) {
    return baseModel.GET(url)
      .then(res => {
        return res
      })
      .catch(error => {
        errorHandler(error)
        return []
      })
  }

  if (params.keywords) {
    url += `?keywords=${params.keywords}`
  }
  if (params.category) {
    url += `${getQueryParamDelimiter(url)}category=${params.category}`
  }
  if (params.minPrice) {
    url += `${getQueryParamDelimiter(url)}minPrice=${params.minPrice}`
  }
  if (params.maxPrice) {
    url += `${getQueryParamDelimiter(url)}maxPrice=${params.maxPrice}`
  }
  return baseModel.GET(url)
    .then(res => {
      return res
    })
    .catch(error => {
      errorHandler(error)
      return []
    })
}

/**
 * 新着アイテム取得
 */
function getNewArrivals () {
  const url = `${ENDPOINT}new_arrival/`
  return baseModel.GET(url)
    .then(res => {
      return res
    })
    .catch(error => {
      errorHandler(error)
      return []
    })
}

/**
 * ポイント交換数ランキング取得
 */
function getRanking () {
  const url = `${ENDPOINT}ranking/`
  return baseModel.GET(url)
    .then(res => {
      return res
    })
    .catch(error => {
      errorHandler(error)
      return []
    })
}

/**
 * アイテム詳細取得
 */
function retrieve (uuid) {
  const url = `${ENDPOINT}${uuid}/`
  return baseModel.GET(url)
    .then(res => {
      return res
    })
    .catch(error => {
      errorHandler(error)
      return {}
    })
}

/**
 * アイテム新規作成
 */
async function create (payload) {
  const url = `${ENDPOINT}`
  return await baseModel.POST(url, payload, 'multipart/form-data')
}

/**
 * アイテム更新
 */
async function update (payload) {
  const url = `${ENDPOINT}${payload.uuid}/`
  return await baseModel.PUT(url, payload.formData, 'multipart/form-data')
}

/**
 * アイテム削除
 */
async function remove (uuid) {
  const url = `${ENDPOINT}${uuid}/`
  return await baseModel.DELETE(url)
}

export default {
  getList,
  getNewArrivals,
  getRanking,
  retrieve,
  create,
  update,
  remove,
  errorHandler: errorHandler
}
