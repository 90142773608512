import baseModel from './BaseModel.js'

const errorHandler = (err) => {
  baseModel.errorHandler(err)
}

const ENDPOINT = 'topic/'

/**
 * お知らせ一覧取得
 */
function getList () {
  const url = `${ENDPOINT}`
  return baseModel.GET(url)
    .then(res => {
      return res
    })
    .catch(error => {
      errorHandler(error)
      return []
    })
}

export default {
  getList,
  errorHandler: errorHandler
}
