<template>
  <v-menu
    open-on-hover
    bottom
    offset-y
  >
    <template #activator="{ on, attrs }">
      <v-icon
        class="icon"
        large
        v-bind="attrs"
        v-on="on"
      >
        mdi-account-circle
      </v-icon>
    </template>

    <v-list class="menu-container">
      <!-- ## profiel ## -->
      <!-- icon image
      <v-list-item>
        <v-list-item-avatar>
          <v-img src="https://cdn.vuetifyjs.com/images/john.png" />
        </v-list-item-avatar>
      </v-list-item>
      -->
      <v-list-item class="profile">
        <v-list-item-content>
          <v-list-item-subtitle
            class="company"
          >
            {{ userInfo.companyName }}
          </v-list-item-subtitle>
          <v-list-item-title
            class="text-h6 username"
          >
            {{ userInfo.username }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="!mdAndUpFlag"
      >
        <v-list-item-content>
          <v-list-item-subtitle
            class="company"
          >
            所持ポイント
          </v-list-item-subtitle>
          <v-list-item-title
            class="text-h6 username"
          >
            {{ myPoint }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- ## menu list ## -->
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        link
        class="menu-list"
      >
        <!-- type: event -->
        <v-list-item-title
          v-if="item.type === 'event'"
          class="menu-title link"
          @click="item.handler"
        >
          {{ item.title }}
        </v-list-item-title>

        <!-- type: internalLink -->
        <router-link
          v-else-if="item.type === 'internalLink'"
          :to="{name: item.path}"
          class="link"
        >
          <v-list-item-title
            class="menu-title"
          >
            {{ item.title }}
          </v-list-item-title>
        </router-link>

        <!-- type: externalLink -->
        <a
          v-else-if="item.type === 'externalLink'"
          :href="item.path"
          target="_blank"
          rel="noopener noreferrer"
          class="link"
        >
          {{ item.title }}
        </a>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import constants from '@/constants.js'

export default {
  name: 'HoverUserMenu',

  /**
   * myPoint: ログインユーザー所有ポイント
   * mdAndUpFlag: $vuetify.breakpoint.mdAndUp
   */
  props: {
    myPoint: {
      type: String,
      required: true
    },
    mdAndUpFlag: {
      type: Boolean,
      required: true
    }
  },

  data () {
    /**
     * username: ログインユーザー名
     * companyNane: ログインユーザー所属企業名
     */
    const userInfo = {
      username: this.getUserName(),
      companyName: this.getCompanyName()
    }
    /**
     * title: リンクテキスト
     * type: internalLink | externalLink | event
     * path?: type === link , パス名
     * handler?: type === event, () => {}
     */
    const items = [
      {
        title: 'ポイント交換履歴',
        type: 'internalLink',
        path: 'ExchangeHistory'
      },
      {
        title: 'Questをみる',
        type: 'externalLink',
        path: constants.QUEST_TOP_URL
      },
      {
        title: 'ログアウト',
        type: 'event',
        handler: this.signOut
      }
    ]
    return {
      items,
      userInfo
    }
  },

  methods: {
    getCompanyName () {
      return this.$store.state.userInfo ? this.$store.state.userInfo.authCompany.name : ''
    },
    getUserName () {
      const userInfo = this.$store.state.userInfo
      return userInfo ? userInfo.lastName + userInfo.firstName : ''
    },
    signOut () {
      this.$store.commit('signOut')
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  color: #fff;
}

.menu-container {
  z-index: 11;

  .menu-title {
    font-size: .9rem;
  }

  .menu-list {
    cursor: pointer;
    border-top: 1px solid #cfc8da;
    padding: 0; // vuetify override

    .link {
      color: #000;
      display: block;
      text-decoration: none;
      width: 100%;
      height: 100%;
      padding: 5px 20px;
    }
  }
}

.profile {
  margin-bottom: 5px;

  .username {
    white-space: nowrap;
  }

  .company {
    display: block;
  }
}
</style>
