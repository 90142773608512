import store from '@/store'
import axios from 'axios'
import constants from '@/constants.js'

/**
 * エラーハンドラ
 */
const errorHandler = (err) => {
  try {
    if (err.response.status === 400) {
      store.commit('message/show', {
        title: 'Error',
        message: err.response.data.message,
        color: 'error',
        timeout: 6000
      })
    } else if (err.response.status === 401) {
      store.commit('message/show', {
        title: 'Error',
        message: 'セッションが切れました。再度ログインしてください。',
        color: 'error',
        timeout: 5000
      })
      store.commit('signOut')
    } else if (err.response.status === 500) {
      store.commit('message/show', {
        title: 'Error',
        message: 'システムに不具合が発生しました。\n申し訳ございませんが、管理者にお問い合わせください。',
        color: 'error',
        timeout: 5000
      })
    }
  } catch (err) {
    store.commit('signOut')
  }
}
/**
 * URL生成
 */
function generateUrl (endpoint) {
  if (endpoint.indexOf('?') > -1) {
    return `${constants.API_HOST}${endpoint}&api_key=${constants.API_KEY}`
  } else {
    return `${constants.API_HOST}${endpoint}?api_key=${constants.API_KEY}`
  }
}

export default {
  errorHandler,
  /* GETリクエスト */
  GET: (endpoint) => {
    const url = generateUrl(endpoint)
    return axios.get(url, { headers: { Authorization: store.getters.accessToken }})
  },
  /* POSTリクエスト */
  POST: async (endpoint, payload, contentType) => {
    const url = generateUrl(endpoint)
    const response = await axios.post(url, payload,
      {
        headers: {
          Authorization: store.getters.accessToken,
          'content-type': contentType
        }
      })
    return response.data
  },
  /* PUTリクエスト */
  PUT: async (endpoint, payload, contentType) => {
    const url = generateUrl(endpoint)
    const response = await axios.put(url, payload,
      {
        headers: {
          Authorization: store.getters.accessToken,
          'content-type': contentType
        }
      })
    return response.data
  },
  /* DELETEリクエスト */
  DELETE: async (endpoint) => {
    const url = generateUrl(endpoint)
    const response = await axios.delete(url, { headers: { Authorization: store.getters.accessToken }})
    return response.data
  }
}
