import store from '@/store'
import Order from '@/models/Order.js'

export default {
  /**
   * 交換申請一覧取得
   */
  async getList (_) {
    if (!store.state.accessToken) {
      return []
    }
    const orders = await Order.getList()
    return orders.data
  },
  /**
   * 交換申請詳細取得
   */
  async getDetail (_, orderNumber) {
    if (!store.state.accessToken) {
      return []
    }
    const order = await Order.getDetail(orderNumber)
    return order.data
  },
  /**
   * 交換申請更新
   */
  async update (_, data) {
    if (!store.state.accessToken) {
      return []
    }
    return await Order.update(data.orderNumber, data.payload)
  },
  /**
   * 交換申請キャンセル
   */
  async cancel (_, data) {
    if (!store.state.accessToken) {
      return []
    }
    return await Order.cancel(data.orderNumber, data.payload)
  }
}
