<template>
  <div class="vld-parent">
    <loading
      :loader="'dots'"
      :active="isShow"
      :is-full-page="fullPage"
      :z-index="9009"
      :color="color"
      :background-color="backgroundColor"
      :opacity="opacity"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  components: {
    Loading
  },

  data: () => ({
    fullPage: true,
    opacity: 1
  }),

  computed: {
    color () {
      return this.$vuetify.theme.themes.light.primary
    },
    backgroundColor () {
      return this.$vuetify.theme.themes.light.background
    },
    isShow () {
      return this.$store.state.loading
    }
  }
}
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
